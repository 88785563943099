import type { IFuseOptions } from 'fuse.js';
import Fuse from 'fuse.js';

const defaultFuseConfig = {
  includeScore: false,
  includeMatches: true,
  threshold: 0.0,
  distance: 0,
  minMatchCharLength: 1,
  ignoreLocation: true,
  shouldSort: false,
  keys: [],
};

function getFuseSearch<T>(
  options: T[],
  query: string,
  keys: string[],
  fuseConfig: IFuseOptions<T> = defaultFuseConfig,
) {
  const fuse = new Fuse(options, { ...fuseConfig, keys });
  return fuse.search(query).map((result) => ({ item: result.item, matches: result.matches }));
}

export { getFuseSearch };
