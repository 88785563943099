import type { FuseResultMatch } from 'fuse.js';

interface SearchProps {
  subtitle?: string;
  label?: string;
  children?: any[];
}

function getFuseChildrenByMatches<T extends SearchProps>(
  children?: T[],
  matches?: readonly FuseResultMatch[],
) {
  if (!children) return [];

  const isMatchOnRootLevel = matches?.some(
    (match) => match.key === 'subtitle' || match.key === 'label',
  );

  const hasMatches = (child: T) => {
    const isSubtitleMatch = matches?.find((match) => match.value === child.subtitle);
    const isLabelMatch = matches?.find((match) => match.value === child.label);
    return isSubtitleMatch || isLabelMatch;
  };

  function filter(array: T[]) {
    return array.reduce((all: T[], o: T) => {
      if (hasMatches(o)) return [...all, o];
      if (o.children) {
        const children = filter(o.children);
        if (children.length) all.push({ ...o, children });
      }
      return all;
    }, []);
  }

  const newChildren = filter(children);

  if (!newChildren.length && isMatchOnRootLevel) return children;

  return newChildren;
}

export { getFuseChildrenByMatches };
