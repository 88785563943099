import type {
  PnNavigationDrawerItemFragment,
  PnNavigationDrawerQuery,
} from '../../../../generated/content/graphql';

export const getNavigationDrawerChildren = (
  data?: PnNavigationDrawerQuery,
): PnNavigationDrawerItemFragment[] => {
  const navigationDrawer = data?.navigationDrawer;

  if (
    !navigationDrawer ||
    (navigationDrawer.__typename !== 'TopLevelTocEntry' &&
      navigationDrawer.__typename !== 'GenericTocEntry')
  ) {
    return [];
  }

  return navigationDrawer.children;
};
