import type { ApolloError } from '@apollo/client';
import { EmptyState, withTitan } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';

import { useContentRendering } from '../../context/ContentRenderingProvider';
import { useStatusCode } from '../../hooks/useStatusCode';

interface ErrorComponentProps {
  error?: ApolloError;
  customErrorComponent?: React.ReactElement;
  forcedErrorState?: number;
}

const ErrorComponentFallback = () => {
  return (
    <EmptyState
      description={
        <Trans>
          Het lijkt erop dat er onverwacht iets fout is gegaan met dit onderdeel van de website. Om
          dit onderdeel van de website te gebruiken, probeer de pagina opnieuw te laden of later de
          pagina opnieuw te openen.
        </Trans>
      }
      illustration="looking-glass"
      testId="errorComponentFallback"
      title={<Trans>Helaas ging er iets fout</Trans>}
    />
  );
};

const ErrorComponent = withTitan<ErrorComponentProps>(
  ({ error, customErrorComponent, forcedErrorState }) => {
    const statusCode = useStatusCode(error);
    const { errorComponent, isPrinting } = useContentRendering();

    if (typeof window === 'undefined' || isPrinting) {
      //  When server side rendering throw the error so printing service can catch it or getdatafromtree will finish early
      throw error;
    }

    const Error = errorComponent;

    if (errorComponent && Error) {
      return <Error error={error} forcedErrorState={forcedErrorState} statusCode={statusCode} />;
    }
    if (customErrorComponent) {
      return customErrorComponent;
    }
    return <ErrorComponentFallback />;
  },
  { memo: true, name: 'ErrorComponent' },
);

export { ErrorComponent, ErrorComponentFallback };
