import { Box, InputVariant, SearchForm, withTitan } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { stickToTop, Sticky } from 'react-unstuck';
import type { UseMeasureRef } from 'react-use/lib/useMeasure';
import styled from 'styled-components';

const CustomSticky = styled(Sticky)`
  left: inherit !important;
`;

interface TocWithAccordionSearchProps {
  searchRef: UseMeasureRef<HTMLDivElement>;
  debouncedSetQuery: (value: string) => void;
  setQuery: (value: string) => void;
}
export const TocWithAccordionSearch = withTitan<TocWithAccordionSearchProps>(
  ({ searchRef, debouncedSetQuery, setQuery, testId }) => {
    useLingui();

    return (
      <CustomSticky behavior={stickToTop} labels={{ drawerHeader: true }}>
        <div ref={searchRef}>
          <Box backgroundColor="grey0" pb={{ xs: 4, s: 8 }} pt={6}>
            <SearchForm
              placeholder={t`Zoeken in inhoudsopgave`}
              testId={testId}
              variant={InputVariant.FILLED}
              onInputChange={debouncedSetQuery}
              onSubmit={setQuery}
            />
          </Box>
        </div>
      </CustomSticky>
    );
  },
  { name: 'TocWithAccordionSearch' },
);
