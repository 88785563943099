import type { ApolloError } from '@apollo/client';
import { DrawerContainer, DrawerContent, withTitan } from '@elseu/sdu-titan';
import type { DrawerContainerProps } from '@elseu/sdu-titan/dist/types/components/Drawer/DrawerContainer';

import { ErrorComponent } from '../../ErrorPage/ErrorComponent';
import { PageLoader } from '../../PageLoader/PageLoader';
import type { CommentaryLawTocData } from './types';

const CommentaryLawTocErrorState = ({
  hasCloseButton,
  error,
}: {
  error: ApolloError;
  hasCloseButton?: DrawerContainerProps['hasCloseButton'];
}) => (
  <DrawerContainer hasCloseButton={hasCloseButton} testId="commentaryLawTocError">
    <DrawerContent>
      <ErrorComponent error={error} />
    </DrawerContent>
  </DrawerContainer>
);

const CommentaryLawTocEmptyState = ({
  hasCloseButton,
}: {
  hasCloseButton?: DrawerContainerProps['hasCloseButton'];
}) => (
  <DrawerContainer hasCloseButton={hasCloseButton} testId="commentaryLawTocNoResults">
    <DrawerContent>
      <ErrorComponent forcedErrorState={1} />
    </DrawerContent>
  </DrawerContainer>
);

export interface CommentaryLawTocContainerProps {
  hasCloseButton?: DrawerContainerProps['hasCloseButton'];
  data: CommentaryLawTocData[];
  isLoading: boolean;
  error?: ApolloError;
}
const CommentaryLawTocContainer = withTitan<
  React.PropsWithChildren<CommentaryLawTocContainerProps>
>(
  ({ hasCloseButton, children, data, isLoading, error }) => {
    if (isLoading) {
      return <PageLoader />;
    }

    if (error) {
      return <CommentaryLawTocErrorState error={error} hasCloseButton={hasCloseButton} />;
    }

    if (!data.length) {
      return <CommentaryLawTocEmptyState hasCloseButton={hasCloseButton} />;
    }

    return <>{children}</>;
  },
  { name: 'CommentaryLawTocContainer' },
);
export { CommentaryLawTocContainer };
