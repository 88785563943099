import type {
  AccordionItemProps,
  AccordionTreeDataProps,
  TreeSource,
  TreeState,
} from '@elseu/sdu-titan';
import { AccordionItem, Highlighter, staticTreeSource } from '@elseu/sdu-titan';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import { debounce } from 'throttle-debounce';

import { addHasChildren } from '../../../helpers/addHasChildren';
import { getFuseChildrenByMatches } from '../../../helpers/getFuseChildrenByMatches';
import { getFuseSearch } from '../../../helpers/getFuseSearch';
import type { PnNavigationTocData } from '../../PnNavigation/PnNavigationToc/PnNavigationTocProvider';

interface useTocWithAccordionProps<T> {
  activeId?: string;
  data: T;
  searchProps: string[];
  headerHeight?: number;
}
export const useTocWithAccordion = ({
  activeId,
  data,
  searchProps,
}: useTocWithAccordionProps<PnNavigationTocData[]>) => {
  const [query, setQuery] = useState('');

  const fuseOptions = useMemo(
    () => getFuseSearch<PnNavigationTocData>(data, query, searchProps),
    [data, query, searchProps],
  );

  /** Need to manually filter out the unmatched items because Fuse only returns matches and all children  */
  const filteredData: PnNavigationTocData[] = useMemo(
    () =>
      fuseOptions.map(({ item: { children, ...rest }, matches }) => ({
        ...rest,
        children: getFuseChildrenByMatches<PnNavigationTocData>(children, matches),
      })),
    [fuseOptions],
  );

  const getExpandedIds = useCallback(
    (data: PnNavigationTocData[]): { [key: string]: boolean } =>
      data.reduce(
        (all: { [key: string]: boolean }, { id, children }) => ({
          ...all,
          ...(children?.length && id ? { [id]: true, ...getExpandedIds(children) } : {}),
        }),
        {},
      ),
    [],
  );

  const expandedIds = useMemo(() => getExpandedIds(filteredData), [filteredData, getExpandedIds]);

  const dataByQuery = useMemo(() => (query ? filteredData : data), [data, filteredData, query]);
  const debouncedSetQuery = useMemo(() => debounce(400, setQuery), []);

  const tree = useMemo<TreeSource<AccordionTreeDataProps>>(() => {
    const treeData = addHasChildren(dataByQuery);
    return staticTreeSource<AccordionTreeDataProps>(treeData);
  }, [dataByQuery]);

  const accordionItemWithHighlighter = useCallback(
    ({ label, subtitle, ...rest }: React.PropsWithChildren<AccordionItemProps>) => (
      <AccordionItem
        {...rest}
        label={<Highlighter terms={query}>{label}</Highlighter>}
        subtitle={<Highlighter terms={query}>{subtitle}</Highlighter>}
      />
    ),
    [query],
  );
  const [searchRef, { height: searchHeight }] = useMeasure<HTMLDivElement>();

  const [treeState, setTreeState] = useState<TreeState>({ activeId, expandedIds });

  useEffect(() => {
    setTreeState({ activeId, expandedIds });
  }, [activeId, expandedIds]);

  return {
    accordionItemWithHighlighter,
    debouncedSetQuery,
    searchHeight,
    searchRef,
    setQuery,
    tree,
    treeState,
    setTreeState,
  };
};
