import { DrawerWithToggle } from '@elseu/sdu-titan';
import type { ReactElement } from 'react';

import type { PnNavigationDrawerContentsProps } from './PnNavigationDrawerContents';
import { PnNavigationDrawerContents } from './PnNavigationDrawerContents';

interface PnNavigationDrawerToggleProps extends PnNavigationDrawerContentsProps {
  button: ReactElement;
}

export const PnNavigationDrawerToggle = ({ button, ...rest }: PnNavigationDrawerToggleProps) => {
  return (
    <DrawerWithToggle button={button} position="right" testId="navigationDrawer" width={500}>
      <PnNavigationDrawerContents {...rest} />
    </DrawerWithToggle>
  );
};
