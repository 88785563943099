import type { ApolloError } from '@apollo/client';
import type { AccordionTreeDataProps } from '@elseu/sdu-titan';
import { DrawerContainer, DrawerContent, useGreaterThan } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useMemo } from 'react';
import { useMeasure } from 'react-use';

import { useContentRendering } from '../../../context/ContentRenderingProvider';
import { usePnNavigationDrawerQuery } from '../../../generated/content/graphql';
import { useContentRenderingUrls } from '../../../hooks/useContentRenderingUrls';
import { AccordionTreeWithScrollContainer } from '../../AccordionTreeWithScrollContainer/AccordionTreeWithScrollContainer';
import { ErrorComponent } from '../../ErrorPage/ErrorComponent';
import { PageLoader } from '../../PageLoader/PageLoader';
import { useTocWithAccordion } from '../../Toc/hooks/useTocWithAccordion';
import { TocWithAccordionSearch } from '../../Toc/TocWithAccordionSearch/TocWithAccordionSearch';
import { getNavigationDrawerChildren } from './helpers/getNavigationDrawerChildren';

export interface PnNavigationDrawerData
  extends Omit<AccordionTreeDataProps, 'label' | 'suffix' | 'prefix' | 'subtitle'> {
  label: string;
  id?: string;
  subtitle?: string;
  children?: PnNavigationDrawerData[];
  hasChildren?: boolean;
}

const PnNavigationDrawerErrorState = ({ error }: { error: ApolloError }) => (
  <DrawerContainer hasCloseButton testId="navigationDrawerError">
    <DrawerContent>
      <ErrorComponent error={error} />
    </DrawerContent>
  </DrawerContainer>
);

const PnNavigationDrawerEmptyState = () => {
  return (
    <DrawerContainer hasCloseButton testId="navigationDrawerNoResults">
      <DrawerContent>
        <ErrorComponent forcedErrorState={1} />
      </DrawerContent>
    </DrawerContainer>
  );
};

const searchProps = [
  'label',
  'children.label',
  'children.children.label',
  'children.children.children.label',
  'children.children.children.children.label',
];

interface PnNavigationDrawerContentProps {
  data: PnNavigationDrawerData[];
  label: string;
  activeId?: string;
  subtitle?: string;
}
const PnNavigationDrawerContent = ({
  data,
  label,
  subtitle,
  activeId,
}: PnNavigationDrawerContentProps) => {
  useLingui();
  const isGreaterThanSmall = useGreaterThan('s');
  const [headerRef, { height: headerHeight }] = useMeasure<HTMLDivElement>();

  const {
    accordionItemWithHighlighter,
    debouncedSetQuery,
    searchRef,
    searchHeight,
    setQuery,
    setTreeState,
    ...props
  } = useTocWithAccordion({
    activeId,
    data,
    searchProps,
    headerHeight,
  });

  return (
    <DrawerContainer
      header={label}
      headerRef={headerRef}
      subtitle={subtitle}
      testId="navigationDrawerResults"
    >
      <DrawerContent>
        <TocWithAccordionSearch
          debouncedSetQuery={debouncedSetQuery}
          searchRef={searchRef}
          setQuery={setQuery}
          testId="navigationDrawerSearch"
        />
        <AccordionTreeWithScrollContainer
          shouldScrollToItem
          customItemRenderer={accordionItemWithHighlighter}
          hasToggleAll={!!isGreaterThanSmall}
          heading="h6"
          labelCloseAll={<Trans>Sluit alles</Trans>}
          labelOpenAll={<Trans>Open alles</Trans>}
          scrollOffset={headerHeight && searchHeight ? searchHeight + headerHeight : 0}
          testId="navigationDrawerAccordionTree"
          onStateChange={setTreeState}
          {...props}
        />
      </DrawerContent>
    </DrawerContainer>
  );
};

export interface PnNavigationDrawerContentsProps {
  /** the documentkey to use */
  documentKey: string;
  /** drawer title */
  label: string;
  /** drawer subtitle */
  subtitle?: string;
  /** active swsId to select in the accordion */
  activeId?: string;
  /** will either be used as the subId or docId in the query, based on the type */
  id?: string;
  /** used to determine the variable name */
  type?: 'subId' | 'docId';
}

const PnNavigationDrawerContents = ({
  documentKey,
  label,
  subtitle,
  id,
  type,
  activeId,
}: PnNavigationDrawerContentsProps) => {
  const { applicationKey, client } = useContentRendering();
  const { contentUrl: baseUrl } = useContentRenderingUrls();
  const {
    loading: isLoading,
    data: response,
    error,
  } = usePnNavigationDrawerQuery({
    variables: {
      documentKey,
      baseUrl,
      applicationKey,
      fetchAll: true,
      ...(type && id ? { [type]: id } : {}),
    },
    client,
  });

  const title = response?.navigationDrawer?.label || label;
  const data = useMemo(() => getNavigationDrawerChildren(response), [response]);

  if (isLoading) {
    return <PageLoader />;
  }

  if (error) {
    return <PnNavigationDrawerErrorState error={error} />;
  }

  if (!data.length) {
    return <PnNavigationDrawerEmptyState />;
  }

  return (
    <PnNavigationDrawerContent activeId={activeId} data={data} label={title} subtitle={subtitle} />
  );
};

export { PnNavigationDrawerContents };
