/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import type { ApolloError } from '@apollo/client';

const useStatusCode = (error?: ApolloError) => {
  let statusCode: number | undefined;
  if (!error?.graphQLErrors) return statusCode;
  error.graphQLErrors.forEach((error) => {
    if (error?.extensions?.statusCode) {
      statusCode = error.extensions.statusCode as number;
    }
  });
  return statusCode;
};

export { useStatusCode };
