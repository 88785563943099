import { DrawerContainer, DrawerContent } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { forwardRef, useMemo } from 'react';
import { useMeasure } from 'react-use';
import styled from 'styled-components';

import { useContentRendering } from '../../../../context';
import { useCommentaryLawTocQuery } from '../../../../generated/content/graphql';
import { useContentRenderingUrls } from '../../../../hooks/useContentRenderingUrls';
import { CommentaryLawTocContainer } from '../CommentaryLawTocContainer';
import { CommentaryLawTocContent } from '../CommentaryLawTocContent';
import type { CommentaryLawTocData } from '../types';

const StyledDrawerContent = styled(DrawerContent)`
  padding-top: 0;
`;
export interface CommentaryLawTocDrawerContainerProps {
  label?: string;
  subtitle?: string;
}
const CommentaryLawTocDrawerContainer = forwardRef(
  (
    { children, label, subtitle }: React.PropsWithChildren<CommentaryLawTocDrawerContainerProps>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    return (
      <DrawerContainer
        header={label}
        headerRef={ref}
        subtitle={subtitle}
        testId="commentaryLawTocDrawerContainer"
      >
        <StyledDrawerContent data-test-id="commentaryLawTocDrawerContent">
          {children}
        </StyledDrawerContent>
      </DrawerContainer>
    );
  },
);

export interface CommentaryLawTocDrawerContentsProps {
  label?: string;
  subtitle?: string;
  swsId?: string;
  lawId: string;
  publicationIdentifiers?: string[];
}

const CommentaryLawTocDrawerContents = ({
  lawId,
  publicationIdentifiers,
  swsId,
  label,
  subtitle,
}: CommentaryLawTocDrawerContentsProps) => {
  useLingui();
  const { applicationKey, client } = useContentRendering();
  const { contentUrl: baseUrl } = useContentRenderingUrls();
  const [headerRef, { height: headerHeight }] = useMeasure<HTMLDivElement>();

  const {
    loading: isLoading,
    data: response,
    error,
  } = useCommentaryLawTocQuery({
    variables: {
      swsId: lawId,
      baseUrl,
      applicationKey,
      publicationIdentifiers,
    },
    client,
  });

  const data: CommentaryLawTocData[] = useMemo(
    () => response?.lawCommentaries?.children || [],
    [response],
  );

  const newLabel = useMemo(
    () => response?.lawCommentaries?.name || label || '',
    [label, response?.lawCommentaries?.name],
  );

  const publications = useMemo(
    () =>
      response?.lawCommentaries?.__typename === 'TopLevelLawTocEntry'
        ? response.lawCommentaries.publications
        : [],
    [response?.lawCommentaries],
  );

  const subtitleByPublicationsLength = useMemo(
    () => (!publications.length ? undefined : t`Te vinden in ${publications.length} publicatie(s)`),
    [publications.length],
  );
  const subtitleByPublications = useMemo(
    () => (publications.length === 1 ? publications[0]?.name : subtitleByPublicationsLength),
    [publications, subtitleByPublicationsLength],
  );

  const newSubtitle = useMemo(
    () => subtitle || subtitleByPublications,
    [subtitle, subtitleByPublications],
  );
  return (
    <CommentaryLawTocContainer hasCloseButton data={data} error={error} isLoading={isLoading}>
      <CommentaryLawTocDrawerContainer ref={headerRef} label={newLabel} subtitle={newSubtitle}>
        <CommentaryLawTocContent
          shouldScrollToItem
          data={data}
          headerHeight={headerHeight}
          swsId={swsId}
        />
      </CommentaryLawTocDrawerContainer>
    </CommentaryLawTocContainer>
  );
};
export { CommentaryLawTocDrawerContents };
