import { Spinner, withTitan } from '@elseu/sdu-titan';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

const PageLoader = withTitan(
  ({ testId = 'pageLoader' }) => {
    return (
      <LoaderContainer data-test-id={testId}>
        <Spinner size={80} />
      </LoaderContainer>
    );
  },
  { name: 'PageLoader' },
);

export { PageLoader };
