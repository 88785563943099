import { DrawerWithToggle } from '@elseu/sdu-titan';
import type { ReactElement } from 'react';

import type { CommentaryLawTocDrawerContentsProps } from './CommentaryLawTocDrawerContents';
import { CommentaryLawTocDrawerContents } from './CommentaryLawTocDrawerContents';

interface CommentaryLawTocDrawerToggleProps extends CommentaryLawTocDrawerContentsProps {
  button: ReactElement;
}

const CommentaryLawTocDrawerToggle: React.FC<CommentaryLawTocDrawerToggleProps> = ({
  button,
  ...rest
}) => {
  return (
    <DrawerWithToggle button={button} position="right" testId="commentaryLawTocDrawer" width={500}>
      <CommentaryLawTocDrawerContents {...rest} />
    </DrawerWithToggle>
  );
};
export { CommentaryLawTocDrawerToggle };
