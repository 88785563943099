import type { AccordionTreeProps } from '@elseu/sdu-titan';
import { AccordionTree, useScrollToActiveAccordionItem } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import { useEffect } from 'react';
import { useScrollElement } from 'react-unstuck';

const AccordionTreeWithScrollContainer = ({
  treeState,
  scrollOffset,
  shouldScrollToItem,
  ...rest
}: AccordionTreeProps & {
  scrollOffset: number;
  shouldScrollToItem?: boolean;
  testId?: string;
}) => {
  const scrollContainer = useScrollElement();
  const scrollToActiveItem = useScrollToActiveAccordionItem({ scrollContainer, scrollOffset });

  useEffect(() => {
    const activeId = treeState?.activeId;

    if (!activeId || !scrollOffset || !shouldScrollToItem) return;

    const timer = setTimeout(() => scrollToActiveItem(activeId), 100);

    return () => {
      clearTimeout(timer);
    };
    // eslint was disabled, because scrollToActiveItem triggered the scrolling effect on every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollOffset, shouldScrollToItem, scrollContainer, treeState?.activeId]);

  return (
    <AccordionTree
      {...rest}
      labelCloseAll={<Trans>Sluit alles</Trans>}
      labelOpenAll={<Trans>Open alles</Trans>}
      treeState={treeState}
    />
  );
};
export { AccordionTreeWithScrollContainer };
