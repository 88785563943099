import { withTitan } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { AccordionTreeWithScrollContainer } from '../../AccordionTreeWithScrollContainer/AccordionTreeWithScrollContainer';
import { useTocWithAccordion } from '../../Toc/hooks/useTocWithAccordion';
import { TocWithAccordionSearch } from '../../Toc/TocWithAccordionSearch/TocWithAccordionSearch';
import type { CommentaryLawTocData } from './types';

const searchProps = [
  'label',
  'subtitle',
  'children.label',
  'children.subtitle',
  'children.children.label',
  'children.children.subtitle',
];

export interface CommentaryLawTocContentProps {
  data: CommentaryLawTocData[];
  swsId?: string;
  headerHeight?: number;
  shouldScrollToItem?: boolean;
}

const CommentaryLawTocContent = withTitan<CommentaryLawTocContentProps>(
  ({ data, swsId, headerHeight, shouldScrollToItem }) => {
    useLingui();

    const {
      accordionItemWithHighlighter,
      debouncedSetQuery,
      searchRef,
      searchHeight,
      setQuery,
      setTreeState,
      ...props
    } = useTocWithAccordion({
      activeId: swsId,
      data,
      searchProps,
      headerHeight,
    });

    return (
      <>
        <TocWithAccordionSearch
          debouncedSetQuery={debouncedSetQuery}
          searchRef={searchRef}
          setQuery={setQuery}
          testId="commentaryLawTocSearch"
        />
        <AccordionTreeWithScrollContainer
          hasToggleAll
          customItemRenderer={accordionItemWithHighlighter}
          heading="h6"
          headingText={<Trans>Inhoudsopgave</Trans>}
          labelCloseAll={<Trans>Sluit alles</Trans>}
          labelOpenAll={<Trans>Open alles</Trans>}
          scrollOffset={headerHeight && searchHeight ? searchHeight + headerHeight : 0}
          shouldScrollToItem={shouldScrollToItem}
          testId="commentaryLawTocAccordionTree"
          onStateChange={setTreeState}
          {...props}
        />
      </>
    );
  },
  { name: 'CommentaryLawTocContent' },
);

export { CommentaryLawTocContent };
